<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
      "
    >
      <a-modal
        :visible="visrecord"
        title="审核进度"
        @ok="oncanrecord"
        @cancel="oncanrecord"
      >
        <a-timeline>
          <a-timeline-item
            v-for="(item, index) in progresslist"
            :key="index"
            :color="
              item.operate == 1
                ? 'blue'
                : item.operate == 2
                ? 'green'
                : item.operate == 3
                ? 'red'
                : 'blue'
            "
          >
            {{ item.positionName }}审批
            <span v-show="item.auditorName != null"
              >{{ item.auditorName }}&nbsp;</span
            >
            <span v-show="item.auditorTime != null"
              >{{ item.auditorTime | time }}&nbsp;</span
            >
            <span>{{
              item.operate == 2 ? '通过' : item.operate == 3 ? '拒绝' : '待审批'
            }}</span>
          </a-timeline-item>
        </a-timeline>
      </a-modal>
      <div class="Enter-top">
        <div>
          <a-breadcrumb style="margin: 18px 0">
            <a-breadcrumb-item>资产管理</a-breadcrumb-item>
            <a-breadcrumb-item>铺位调整</a-breadcrumb-item>
          </a-breadcrumb>
        </div>

        <div v-if="form.type == null" class="Enter-top-right">
          <a-button @click="merge"> 铺位合并 </a-button>
          <a-button @click="Breakup"> 拆分铺位 </a-button>
          <a-button type="danger" @click="onDetele"> 删除 </a-button>
        </div>
        <div v-else>
          <a-icon
            @click="
              () => {
                this.form.type = null
                this.visible = false
              }
            "
            style="
              width: 30px;
              margin-right: 5px;
              cursor: pointer;
              font-size: 25px;
            "
            type="rollback"
          />
        </div>
      </div>
    </a-layout-content>
    <a-layout
      v-if="visible == false"
      style="padding: 24px; height: 100%; overflow: auto"
    >
      <a-layout-content
        style="
          background-color: #fff;
          line-height: 60px;
          margin: 0;
          width: 100%;
        "
      >
        <a-form
          style="padding: 0 24px"
          class="ant-advanced-search-form"
          :form="fom"
          @submit="handleSearch"
        >
          <a-row class="flex-d-row" style="flex-wrap: wrap" :gutter="24">
            <a-col v-for="(i, index) in textlist" :key="index">
              <a-form-item style="margin-bottom: 0" :label="i">
                <a-select
                  v-if="i == '状态'"
                  placeholder="请选择状态"
                  style="width: 178px"
                  v-model="params.state.value"
                >
                  <a-select-option value="0"> 未提交 </a-select-option>
                  <a-select-option value="1"> 已提交 </a-select-option>
                  <a-select-option value="2"> 审核通过 </a-select-option>
                  <a-select-option value="3"> 审核拒绝 </a-select-option>
                </a-select>
                <a-select
                  v-else-if="i == '拆分类型'"
                  placeholder="请选择类型"
                  style="width: 178px"
                  v-model="params.type.value"
                >
                  <a-select-option value="0"> 拆分 </a-select-option>
                  <a-select-option value="1"> 合并 </a-select-option>
                </a-select>
                <a-date-picker
                  v-model="params.ct_time.value"
                  style="width: 178px"
                  v-else-if="i == '创建时间'"
                />
                <a-input
                  v-else
                  style="width: 178px"
                  v-decorator="[
                    `${i}`,
                    {
                      rules: [],
                    },
                  ]"
                  :placeholder="'请输入' + i"
                />
              </a-form-item>
            </a-col>
            <a-col class="a-col-top">
              <a-button type="primary" html-type="submit"> 搜索 </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
                清空
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </a-layout-content>
      <a-layout-content
        :style="{
          background: '#fff',
          padding: '24px',
          margin: 0,
        }"
      >
        <!-- 表格 -->
        <div class="search-wrap">
          <div style="width: 100%">
            <a-table
              :row-selection="rowSelection"
              rowKey="id"
              :pagination="false"
              :columns="columns"
              :data-source="storeAdjustlist"
              :rowClassName="rowClassName"
            >
              <span slot="time" slot-scope="text">
                {{ text | time }}
              </span>
              <span slot="type" slot-scope="text">
                {{ text == 0 ? '拆分' : '合并' }}
              </span>
              <span slot="status" slot-scope="text">
                <a-badge
                  :status="text | statusTypeFilter"
                  :text="text | statusFilter"
                />
              </span>
              <template slot="action" slot-scope="text, record">
                <a v-show="record.state == 0" @click="onEdit(record)"
                  >编辑&nbsp;</a
                >
                <a @click="onaudit(record)">{{
                  record.state == 0
                    ? '提交'
                    : record.state == 1
                    ? '撤回'
                    : record.state == 3
                    ? '重新编辑'
                    : ' '
                }}</a
                >&nbsp;
                <a v-show="record.state == 1" @click="onrecords(record)"
                  >审核进度</a
                >
              </template>
            </a-table>
            <a-pagination
              v-model="params.current"
              :page-size="params.pageSize"
              show-quick-jumper
              :default-current="2"
              :total="total"
              @change="onpagesize"
            />
          </div>
          <a-modal
            title="审核"
            :visible="auditvisible"
            @ok="onauditsub"
            @cancel="onauditcancel"
          >
            <a-form-model-item label="备注">
              <a-input placeholder="请输入备注" v-model="submitNote" />
            </a-form-model-item>
            <a-upload
              action=""
              :multiple="true"
              :file-list="submitAttach"
              :customRequest="handleauditinfo"
              @change="handleauditinfos"
            >
              <a-button> <a-icon type="upload" /> 点击上传 </a-button>
            </a-upload>
          </a-modal>
        </div>
      </a-layout-content>
      <!-- <a-layout-content style="textAlign: center;padding-top:12px">
        {{ this.$store.state.infos.shortName }} &nbsp;&nbsp;{{
          this.$store.state.infos.icp
        }}
        &nbsp;&nbsp;{{
          this.$store.state.infos.police == ""
            ? ""
            : this.$store.state.infos.police
        }}
      </a-layout-content> -->
    </a-layout>
    <!-- 编辑页 -->
    <a-layout v-else style="padding: 0 24px; height: 100%; overflow: auto">
      <a-layout-content style="background: #fff; margin: 24px 0">
        <div class="content-title">
          <span>基础信息</span>
        </div>
        <a-form-model
          style="flex-wrap: wrap"
          class="content-body flex-d-row"
          :model="form"
          ref="ruleForms"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item prop="projectId" label="项目名称">
            <a-select
              style="width: 100%"
              @change="properyChange"
              placeholder="请选择项目"
              disabled="disabled"
              v-model="form.projectId"
            >
              <a-select-option
                v-for="item in projectlist"
                :key="item.id"
                :value="item.id"
              >
                {{ item.shortName }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="buildingId" label="楼栋">
            <a-select
              style="width: 100%"
              @change="buildChange"
              placeholder="请选择楼栋"
              v-model="form.buildingId"
            >
              <a-select-option v-for="item in buildlist" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="floorId" label="楼层">
            <a-select
              v-model="form.floorId"
              style="width: 100%"
              @change="floorChange"
              placeholder="请选择楼层"
            >
              <a-select-option v-for="item in floorlist" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-if="form.type == 1" prop="olds" label="铺位号">
            <a-select
              mode="tags"
              style="width: 100%"
              v-model="form.olds"
              placeholder="请选择铺位号"
            >
              <a-select-option v-for="item in storelist" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item v-else prop="olds" label="铺位号">
            <a-select
              style="width: 100%"
              v-model="form.olds"
              placeholder="请选择铺位号"
            >
              <a-select-option v-for="item in storelist" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            style="width: 50%"
            prop="description"
            ref="description"
            label="介绍"
          >
            <a-input placeholder="请简述介绍" v-model="form.description" />
          </a-form-model-item>
        </a-form-model>
      </a-layout-content>
      <!-- 新铺位信息 -->
      <a-layout-content
        v-if="form.type == 0"
        style="background: #fff; margin: 0 0 24px 0"
      >
        <div class="content-title">
          <span>新铺位信息</span>
        </div>
        <div class="flex-d-row">
          <div
            v-for="(item, index) in form.news"
            :key="item.name"
            @click="onOPennews(item, index)"
            class="content-news"
          >
            {{ item.name }}
          </div>
          <div @click="onOPennews(null)" class="content-news">+</div>
        </div>
      </a-layout-content>
      <a-layout-content v-else>
        <a-form-model
          ref="ruleForm"
          :model="newstore"
          :rules="ruless"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-layout-content style="background: #fff; margin: 0 0 24px 0">
            <div class="content-title">
              <span>新-基础信息</span>
            </div>
            <div style="flex-wrap: wrap" class="content-body flex-d-row">
              <a-form-model-item label="项目名称">
                <a-select
                  style="width: 100%"
                  @change="properyChange"
                  placeholder="请选择项目"
                  disabled="disabled"
                  v-model="form.projectId"
                >
                  <a-select-option
                    v-for="item in projectlist"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.shortName }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="楼栋">
                <a-select
                  style="width: 100%"
                  @change="buildChange"
                  disabled="disabled"
                  placeholder="请选择楼栋"
                  v-model="form.buildingId"
                >
                  <a-select-option v-for="item in buildlist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="楼层">
                <a-select
                  v-model="form.floorId"
                  style="width: 100%"
                  disabled="disabled"
                  @change="floorChange"
                  placeholder="请选择楼层"
                >
                  <a-select-option v-for="item in floorlist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item ref="name" prop="name" label="铺位号">
                <a-input
                  placeholder="请输入铺位号"
                  @blur="
                    () => {
                      $refs.name.onFieldBlur()
                    }
                  "
                  v-model="newstore.name"
                />
              </a-form-model-item>
              <a-form-model-item
                style="width: 20%"
                prop="propertyTypeId"
                label="产权属性"
              >
                <a-select
                  style="width: 100%"
                  :filter-option="filterOption"
                  @change="typeChange"
                  placeholder="请选择产权"
                  v-model="newstore.propertyTypeId"
                >
                  <a-select-option v-for="item in typelist" :key="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item
                ref="totalArea"
                prop="totalArea"
                label="建筑面积"
                style="width: 20%"
              >
                <a-input
                  placeholder="请输入建筑面积"
                  @blur="
                    () => {
                      $refs.name.onFieldBlur()
                    }
                  "
                  v-model="newstore.totalArea"
                />
              </a-form-model-item>
              <a-form-model-item
                ref="useArea"
                style="width: 20%"
                prop="useArea"
                label="使用面积"
              >
                <a-input
                  placeholder="请输入使用面积"
                  @blur="
                    () => {
                      $refs.name.onFieldBlur()
                    }
                  "
                  v-model="newstore.useArea"
                />
              </a-form-model-item>
              <a-form-model-item
                ref="rentArea"
                style="width: 20%"
                prop="rentArea"
                label="计租面积"
              >
                <a-input
                  placeholder="请输入计租面积"
                  @blur="
                    () => {
                      $refs.name.onFieldBlur()
                    }
                  "
                  v-model="newstore.rentArea"
                />
              </a-form-model-item>
              <a-form-model-item prop="state" style="width: 20%" label="状态">
                <a-select
                  style="width: 100%"
                  show-search
                  :filter-option="filterOption"
                  v-model="newstore.state"
                >
                  <a-select-option value="0">启用</a-select-option>
                  <a-select-option value="1">禁用</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-layout-content>
          <!-- 价格 -->
          <a-layout-content style="background: #fff; margin: 0 0 24px 0">
            <div class="content-title">
              <span>新-价格信息</span>
            </div>
            <div style="flex-wrap: wrap" class="content-body flex-d-row">
              <a-form-model-item style="width: 20%" label="单位">
                <a-input disabled="disabled" value="元/m²·天" />
              </a-form-model-item>
              <a-form-model-item
                ref="dayLowestFee"
                prop="dayLowestFee"
                class="price-formitem"
                label="日标准价格"
              >
                <a-input
                  @blur="
                    () => {
                      $refs.name.onFieldBlur()
                    }
                  "
                  placeholder="请输入标准价格"
                  v-model="newstore.dayLowestFee"
                />
              </a-form-model-item>
              <a-form-model-item
                ref="dayStandardFee"
                prop="dayStandardFee"
                class="price-formitem"
                label="最低价格"
              >
                <a-input
                  @blur="
                    () => {
                      $refs.name.onFieldBlur()
                    }
                  "
                  placeholder="请输入最低价格"
                  v-model="newstore.dayStandardFee"
                />
              </a-form-model-item>
            </div>
          </a-layout-content>
        </a-form-model>
        <!-- 图片 -->
        <a-layout-content
          style="background: #fff; margin-bottom: 24px; height: 217px"
        >
          <div class="content-title">
            <span>新-图片</span>
          </div>
          <div class="content-body">
            <div class="clearfix">
              <a-upload
                action=""
                list-type="picture-card"
                :file-list="newstore.images"
                @preview="handlePreview"
                :customRequest="handleChange"
                @change="handleChanges"
              >
                <div v-if="newstore.images.length < 5">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </div>
          </div>
        </a-layout-content>
        <!-- 扩展信息 -->
        <a-layout-content
          :style="{
            background: '#fff',
            margin: 0,
          }"
        >
          <div class="content-title">
            <span>新-扩展信息</span>
            <a-icon
              v-if="extension == 0"
              @click="
                () => {
                  this.extension = 1
                }
              "
              style="cursor: pointer"
              type="down"
            />
            <a-icon
              v-else
              @click="
                () => {
                  this.extension = 0
                }
              "
              style="cursor: pointer"
              type="up"
            />
          </div>
          <div v-show="extension == 1">a</div>
        </a-layout-content>
      </a-layout-content>
      <!-- <a-layout-footer style="textAlign: center">
        {{ this.$store.state.infos.shortName }} &nbsp;&nbsp;{{
          this.$store.state.infos.icp
        }}
        &nbsp;&nbsp;{{
          this.$store.state.infos.police == ""
            ? ""
            : this.$store.state.infos.police
        }}
      </a-layout-footer> -->
    </a-layout>
    <a-layout-content
      v-if="visible == true"
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 70px;
        text-align: center;
      "
    >
      <div save-draw v-if="form.type == 2">
        <a-button type="primary" @click="onSavenews"> 保存 </a-button>
        <a-button @click="onreset" style="margin-left: 40px"> 返回 </a-button>
      </div>
      <div v-else save-draw>
        <a-button type="primary" @click.prevent="onSubmit"> 提交 </a-button>
        <a-button @click="onClose" style="margin-left: 40px"> 取消 </a-button>
      </div>
    </a-layout-content>
  </a-layout>
</template>
<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
const columns = [
  {
    title: '拆分类型',
    dataIndex: 'type',
    scopedSlots: { customRender: 'type' },
  },
  {
    title: '原铺位',
    dataIndex: 'oldNames',
  },
  {
    title: '新铺位',
    dataIndex: 'newNames',
  },
  {
    title: '备注',
    dataIndex: 'description',
  },

  {
    title: '创建时间',
    dataIndex: 'createTime',
    scopedSlots: { customRender: 'time' },
  },
  {
    title: '状态',
    dataIndex: 'state',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    dataIndex: 'id',
    scopedSlots: { customRender: 'action' },
  },
]
const statusMap = {
  0: {
    status: 'warning',
    text: '未提交',
  },
  1: {
    status: 'processing',
    text: '已提交',
  },
  2: {
    status: 'success',
    text: '审核通过',
  },
  3: {
    status: 'error',
    text: '审核拒绝',
  },
}
const OSS = require('ali-oss')
let client
import { nanoid } from 'nanoid'
// import { api } from "@/http/api";
import { imageMixin } from '../../../../mixins'
import http from '../../../../http'
export default {
  mixins: [imageMixin],
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      extension: 0,
      file: null,
      // 行内
      columns,
      visrecord: false,
      loading: false,
      auditvisible: false,
      visible: false,
      labelCol: { span: 12 },
      wrapperCol: { span: 24 },
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      textlist: ['拆分类型', '备注', '创建时间', '状态'],
      expand: false,
      fom: this.$form.createForm(this, { name: 'advanced_search' }),
      // 要传参数
      projectlist: [],
      buildlist: [],
      floorlist: [],
      storelist: [],
      typelist: [],
      storeAdjustlist: [],
      selectedRowKey: [],
      linkId: '',
      submitNote: '',
      submitAttach: [],
      progresslist: [],
      params: {
        current: 1,
        pageSize: 10,
        type: {
          value: '',
          op: '=',
        },
        cn_note: {
          value: '',
          op: '%like%',
        },
        ct_time: {
          value: undefined,
          op: '=',
        },
        state: {
          value: undefined,
          op: '=',
        },
      },
      total: 6,
      Id: '',
      index: null,
      form: {
        id: '',
        state: '0',
        name: '',
        buildname: '',
        buildingId: '',
        floorId: '',
        olds: [],
        title: '改变',
        description: '',
        projectId:
          JSON.parse(localStorage.getItem('store-userlist')).projectInfo
            .projectId || '',
        type: null,
        news: [],
      },
      newstore: {
        name: '',
        totalArea: '',
        useArea: '',
        rentArea: '',
        dayLowestFee: '',
        dayStandardFee: '',
        monthLowestFee: '',
        monthStandardFee: '',
        floorId: '',
        state: '0',
        images: [],
        propertyTypeId: '1426051906128113665',
      },
      rules: {
        projectId: [
          {
            required: true,
            message: '请选择项目',
            trigger: 'change',
          },
        ],
        buildingId: [
          {
            required: true,
            message: '请选择楼栋',
            trigger: 'change',
          },
        ],
        floorId: [
          {
            required: true,
            message: '请选择楼层',
            trigger: 'change',
          },
        ],
        olds: [
          {
            required: true,
            message: '铺位号不能为空',
            trigger: 'blur',
          },
        ],
        description: [
          {
            required: true,
            message: '简介不能为空',
            trigger: 'blur',
          },
        ],
      },
      ruless: {
        floorId: [
          {
            required: true,
            message: '请选择楼层',
            trigger: 'change',
          },
        ],
        name: [
          {
            required: true,
            message: '铺位号不能为空',
            trigger: 'blur',
          },
        ],
        propertyTypeId: [
          {
            required: true,
            message: '请选择产权属性',
            trigger: 'change',
          },
        ],
        totalArea: [
          {
            required: true,
            message: '建造面积不能为空',
            trigger: 'blur',
          },
        ],
        useArea: [
          {
            required: true,
            message: '使用面积不能为空',
            trigger: 'blur',
          },
        ],
        rentArea: [
          {
            required: true,
            message: '计租面积不能为空',
            trigger: 'blur',
          },
        ],
        state: [
          {
            required: true,
            message: '招商状态不能为空',
            trigger: 'change',
          },
        ],
        monthLowestFee: [
          {
            required: true,
            message: '月标准价格不能为空',
            trigger: 'blur',
          },
        ],
        monthStandardFee: [
          {
            required: true,
            message: '月最低价格不能为空',
            trigger: 'blur',
          },
        ],
        dayLowestFee: [
          {
            required: true,
            message: '日标准价格不能为空',
            trigger: 'blur',
          },
        ],
        dayStandardFee: [
          {
            required: true,
            message: '日最低价格不能为空',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  filters: {
    statusFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return statusMap[type].text
      }
    },
    statusTypeFilter(type) {
      if (type == null) {
        return ' '
      } else {
        return statusMap[type].status || ''
      }
    },
    time(time) {
      if (time == null) {
        return ' '
      } else {
        var times = time.split('T')
        return times[0] + ' ' + times[1]
      }
    },
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.params.current = 1
      this.fom.validateFields((error, values) => {
        this.params.cn_note.value = values.备注
        // this.params.company_name.value = values.公司名称;
        // this.params.mian_ji_max.value = values.需求面积;
      })
      this.getstoreAdjust()
    },

    handleReset() {
      this.fom.resetFields()
    },

    toggle() {
      this.expand = !this.expand
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    handleChanges({ fileList }) {
      if (this.newstore.images.length > fileList.length) {
        this.newstore.images = fileList
      }
    },
    handleChange(file) {
      var data = file.file

      this.putObject(
        0,
        client,
        nanoid(),
        file.file.name.split('.')[1],
        data,
        file.file.name
      )
    },
    async putObject(index, client, id, name, data, realName) {
      try {
        const result = await client.put(`sccn/${id}.${name}`, data)
        let url
        url = client.signatureUrl(result.name)
        var a = [result]
        if (index == 0) {
          a.forEach(item => {
            this.newstore.images.push({
              bucket: 'sccncloud2',
              name: item.name,
              uid: id,
              status: 'done',
              url: url,
            })
          })
        } else {
          a.forEach(item => {
            this.submitAttach.push({
              bucket: 'sccncloud2',
              name: item.name,
              realName: realName,
              type: name,
              uid: id,
              status: 'done',
              url: url,
            })
          })
        }
      } catch (e) {
        console.log(e)
      }
    },
    merge() {
      this.visible = true
      this.form.type = 1
      localStorage.setItem('selectedKeys', ['/property/storeAdjust?h'])
      this.$store.commit('selectKeys')
      this.buildingid(
        JSON.parse(localStorage.getItem('store-userlist')).projectInfo.projectId
      )
    },
    Breakup() {
      this.visible = true
      this.form.type = 0
      localStorage.setItem('selectedKeys', ['/property/storeAdjust?c'])
      this.$store.commit('selectKeys')
      this.buildingid(
        JSON.parse(localStorage.getItem('store-userlist')).projectInfo.projectId
      )
    },
    onOPennews(e, index) {
      //   console.log(e);
      this.index = index
      if (e == null) {
        this.index = null
        this.newstore = {
          name: '',
          totalArea: '',
          useArea: '',
          rentArea: '',
          dayLowestFee: '',
          dayStandardFee: '',
          monthLowestFee: '',
          monthStandardFee: '',
          floorId: '',
          state: '0',
          images: [],
          propertyTypeId: '1426051906128113665',
        }
      } else {
        e.state = e.state + ''
        e.images = JSON.parse(e.images)
        this.newstore = e
      }
      this.form.type = 2
    },
    onSavenews() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.newstore.images = JSON.stringify(this.newstore.images)
          //   console.log(this.index);
          if (this.index == null) {
            this.form.news.push(this.newstore)
          } else {
            this.form.news.splice(this.index, 1, this.newstore)
          }
          this.form.type = 0
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    },
    onreset() {
      this.form.type = 0
      this.newstore.images = JSON.stringify(this.newstore.images)
    },
    onClose() {
      this.form = {
        id: '',
        state: '0',
        name: '',
        buildname: '',
        buildingId: '',
        floorId: '',
        olds: [],
        title: '改变',
        description: '',
        projectId:
          JSON.parse(localStorage.getItem('store-userlist')).projectInfo
            .projectId || '',
        type: null,
        news: [],
      }
      this.newstore = {
        name: '',
        totalArea: '',
        useArea: '',
        rentArea: '',
        dayLowestFee: '',
        dayStandardFee: '',
        monthLowestFee: '',
        monthStandardFee: '',
        floorId: '',
        state: '0',
        images: [],
        propertyTypeId: '1426051906128113665',
      }
      window.location = window.location.href.split('?')[0]
      this.visible = false
      //   console.log(this.form.type);
    },
    // 编辑
    onEdit(e) {
      this.Id = e.id
      this.form.id = e.id
      this.visible = true
      this.title = '编辑'
      this.getstoreAdjustId(e.id)
    },
    onSubmit() {
      this.$refs.ruleForms.validate(valid => {
        if (valid) {
          //   console.log("submit!", this.form);
          if (this.form.type == 0) {
            if (this.form.id == '') {
              var a = this.form.olds
              this.form.olds = [a]
            }
          } else if (this.form.type == 1) {
            this.newstore.images = JSON.stringify(this.newstore.images)
            this.form.news.splice(0, 1, this.newstore)
          }
          //   console.log(this.form.id);
          if (this.form.id == '') {
            this.StoreAdjust(this.form)
          } else {
            this.form.news.forEach(item => {
              item.images = JSON.stringify(item.images)
              item.floorId = this.form.floorId
            })
            this.putStoreAdjust(this.form)
          }
        } else {
          this.$message.error('必填项不能为空')
          return false
        }
      })
    },
    //审核
    onaudit(e) {
      this.auditvisible = true
      this.form.state = e.state
      this.linkId = e.id
    },
    onauditsub() {
      this.submitAttach = JSON.stringify(this.submitAttach)
      if (this.form.state == 0) {
        this.StoreAdjustsubmit()
      } else if (this.form.state == 1) {
        this.StoreAdjustcancel()
      } else {
        this.StoreAdjustreset()
      }
    },
    onauditcancel() {
      this.linkId = ''
      this.submitNote = ''
      this.submitAttach = []
      this.auditvisible = false
    },
    handleauditinfo(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} 上传成功`)
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} 上传失败`)
      }
      var data = info.file
      this.putObject(
        1,
        client,
        nanoid(),
        info.file.name.split('.')[info.file.name.split('.').length - 1],
        data
      )
    },
    handleauditinfos({ fileList }) {
      if (this.submitAttach.length > fileList.length) {
        this.submitAttach = fileList
      }
    },
    async StoreAdjustsubmit() {
      try {
        const res = await http.StoreAdjustsubmit({
          linkId: this.linkId,
          submitNote: this.submitNote,
          submitAttach: this.submitAttach,
        })
        const { success, msg } = res.data
        if (success) {
          this.onauditcancel()
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
        console.log('ex:', ex)
      }
    },
    async StoreAdjustcancel() {
      try {
        const res = await http.StoreAdjustcancel({
          linkId: this.linkId,
          submitNote: this.submitNote,
          submitAttach: this.submitAttach,
        })
        const { success, msg } = res.data
        if (success) {
          this.onauditcancel()
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
        console.log('ex:', ex)
      }
    },
    async StoreAdjustreset() {
      try {
        const res = await http.StoreAdjustreset({
          linkId: this.linkId,
          submitNote: this.submitNote,
          submitAttach: this.submitAttach,
        })
        const { success, msg } = res.data
        if (success) {
          this.onauditcancel()
          this.timer()
          this.$message.success('操作成功')
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        this.$message.error(ex)
        console.log('ex:', ex)
      }
    },
    //
    //

    // 结束
    onpagesize(e) {
      this.params.current = e
      this.getStore()
    },
    async getstoreAdjustId(id) {
      try {
        const res = await http.getstoreAdjustId(id)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          //   this.form.id = data.storeAdjust.id;
          this.form.state = data.storeAdjust.state
          this.form.name = data.storeAdjust.name
          this.form.title = data.storeAdjust.title
          this.form.description = data.storeAdjust.description
          this.form.type = data.storeAdjust.type
          this.form.news = data.storeAdjust.news
          this.project()
          this.form.projectId = data.storeAdjust.projectId
          this.form.buildingId = data.storeAdjust.buildingId
          this.form.floorId = data.storeAdjust.floorId
          this.buildingid(data.storeAdjust.projectId)
          this.floorid(data.storeAdjust.buildingId)
          this.storeid(data.storeAdjust.floorId)
          this.type()

          if (data.storeAdjust.type == 0) {
            this.form.olds = data.storeAdjust.olds
          } else {
            this.newstore = this.form.news[0]
            setTimeout(() => {
              this.newstore.images = JSON.parse(
                JSON.parse(this.form.news[0].images)
              )
              var a = []
              this.newstore.images.forEach(item => {
                let url
                url = client.signatureUrl(item.name)
                a.push({
                  bucket: 'sccncloud2',
                  name: item.name,
                  uid: nanoid(),
                  status: 'done',
                  url: url,
                })
              })
              this.newstore.images = a
            }, 100)
            this.form.olds = data.storeAdjust.olds
          }
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    // 请求
    async getstoreAdjust() {
      try {
        const res = await http.getstoreAdjust(this.params)
        const { success, data } = res.data
        if (success) {
          this.storeAdjustlist = data.storeAdjusts.records
          this.total = data.storeAdjusts.total
          //   console.log(data);
        }
      } catch (ex) {
        console.log('ex:', ex)
      }
    },
    async StoreAdjust(form) {
      try {
        const res = await http.StoreAdjust(form)
        const { success, msg } = res.data
        if (success) {
          this.getstoreAdjust()
          this.$message.success(msg)
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('ex:', ex)
        this.$message.error(ex)
      }
    },
    async putStoreAdjust(form) {
      try {
        const res = await http.putStoreAdjust(form)
        const { success, msg } = res.data
        if (success) {
          this.$message.success(msg)
          this.onClose()
        } else {
          this.$message.warning(msg)
        }
      } catch (ex) {
        console.log('ex:', ex)
        this.$message.error(ex)
      }
    },
    onrecords(e) {
      this.visrecord = true
      this.getauditrecord(0, e.id, e.processId, e.version)
    },
    oncanrecord() {
      this.visrecord = false
      setTimeout(() => {
        this.progresslist = []
      }, 100)
    },
    async getauditrecord(linkType, linkId, processId, version) {
      try {
        const res = await http.getauditrecord(
          linkType,
          linkId,
          processId,
          version
        )
        const { success, data } = res.data
        if (success) {
          this.progresslist = data.record
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    // 删除
    onDetele() {
      let that = this
      this.$confirm({
        title: '确认要删除该铺位调整么?',
        okText: '是',
        cancelText: '否',
        onOk() {
          setTimeout(() => {
            that.deleteAdjust()
          }, 100)
        },
        onCancel() {
          console.log()
        },
      })
    },
    async deleteAdjust() {
      try {
        const res = await http.deleteAdjust(this.selectedRowKey)
        const { success, msg } = res.data
        if (success) {
          this.$message.success(msg)
        } else {
          this.$message.success(msg)
        }
        this.timer()
      } catch (ex) {
        console.log('error:' + ex)
        this.$message.error(ex)
      }
    },
    //Change事件
    buildChange(value) {
      this.floorlist = []
      this.Storelist = []
      this.newstore.floorId = ''
      this.newstore.name = ''
      this.form.floorId = ''
      this.form.olds = ''
      //   console.log(value);
      this.floorid(value)
    },
    floorChange(value) {
      this.Storelist = []
      this.newstore.name = ''
      this.newstore.floorId = this.form.floorId
      this.form.olds = ''
      //   console.log(value);
      this.storeid(value)
    },

    //下拉框
    async project() {
      try {
        const res = await http.project()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.projectlist = data.projects
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async buildingid(projectId) {
      try {
        const res = await http.buildingid(projectId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.buildlist = data.buildings
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async floorid(buildingId) {
      try {
        const res = await http.floorid(buildingId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.floorlist = data.floors
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async storeid(floorId) {
      try {
        const res = await http.storeid(floorId)
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.storelist = data.stores
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async type() {
      try {
        const res = await http.type()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.typelist = data.propertyTypes
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    rowClassName(record, index) {
      let className = 'light'
      if (index % 2 === 1) className = 'dark'
      return className
    },
    timer() {
      return setTimeout(() => {
        this.getstoreAdjust()
      }, 500)
    },
    beforeDestroy() {
      clearInterval(this.timer)
      this.timer = null
    },
  },
  mounted() {
    this.getstoreAdjust()
    this.getsts()
    this.project()
    if (this.$route.query.index == 'h') {
      this.merge()
    } else if (this.$route.query.index == 'c') {
      this.Breakup()
    }
    this.type()
  },
  computed: {
    count() {
      return this.expand ? 6 : 5
    },
    rowSelection() {
      return {
        onChange: (selectedRowKey, selectedRows) => {
          this.selectedRowKey = selectedRowKey
          //   console.log("selectedRowKey:" + this.selectedRowKey);
          console.log('selectedRows:', selectedRows)
        },
      }
    },
  },
}
</script>
<style lang="less" scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  //   box-shadow: 0 6px 12px 0 rgb(0 0 0 / 4%);
  border-radius: 3px;
  position: relative;
  height: 100%;
  min-height: 730px;
  padding: 10px 0 6px;
  margin-bottom: 15px;
}
.price {
  position: absolute;
  right: 1px;
  bottom: -6px;
  width: 70px;
  height: 30px;
  line-height: 32px;
  text-align: center;
  background-color: #fafafa;
  border-left: 1px solid #d9d9d9;
}
.ant-btn {
  margin: 0px 8px;
}
.flex-d-row {
  display: flex;
  flex-direction: row;
}
.flex-d-column {
  display: flex;
  flex-direction: column;
}
.ant-pagination {
  float: right;
  margin-top: 10px;
}
.ant-layout {
  height: 100%;
}
.Enter-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 24px;
  .Enter-top-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 15px;
    .top-right-inpot {
      height: 32px;
      font-size: 13px;
      color: orange;
      border-radius: 22px;
      margin: 0 10px;
      border: 1px solid orange;
    }
  }
}
.content-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 24px;
  border-bottom: 1px solid #eaeaea;
}
.content-body {
  padding: 24px;
  .ant-form-item {
    height: 90px;
    margin: 0;
    width: 25%;
    padding-right: 20px;
  }
}
.content-body .price-formitem {
  position: relative;
  width: 40%;
}
.content-news {
  width: 80px;
  height: 80px;
  margin: 24px 0 0 24px;
  border: 1px dashed #d9d9d9;
  line-height: 80px;
  text-align: center;
}
.updata > img {
  border-radius: 4px;
  margin-right: 5px;
  border: 1px dashed #d9d9d9;
  text-align: center;
  height: 102px;
  width: 102px;
}
.content-news:hover {
  cursor: pointer;
}
::v-deep .ant-input-affix-wrapper .ant-input-suffix {
  left: 12px;
}
::v-deep .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 0;
}
.ant-input {
  border-radius: 0;
  padding-left: 10px;
  color: #000;
}
::v-deep .ant-form-item-label {
  text-align: left;
}
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
.a-col-top {
  position: absolute;
  right: 0;
  top: 43px;
}
.a {
  color: #5f5f5f;
}
.a:hover {
  color: #1890ff;
}
</style>
